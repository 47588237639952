import React from "react"

const DrawerButton = ({ buttonText, handleToggle, disable }) => {
    return (
        <button className={`btn btn--outlined drawer-button ${disable ? "disabled" : ""}`} onClick={handleToggle}>
            {buttonText}
        </button>
    )
}

export default DrawerButton
