import React from "react"
import DrawerButton from "./Drawer.button"

const Drawer = ({ children, toggleDrawer, showDrawer, buttonText, disable }) => {
    return (
        <div className={`drawer-container ${!showDrawer ? "" : "active"}`}>
            <DrawerButton buttonText={buttonText} handleToggle={() => toggleDrawer()} disable={disable} />
            <div className={`drawer-background opaque ${!showDrawer ? "" : "active"}`} onClick={() => toggleDrawer()}></div>
            <div className={`drawer ${!showDrawer ? "drawer--hidden" : ""}`}>{children}</div>
            <div className="hover__component"></div>
        </div>
    )
}

export default Drawer
